import Vue from 'vue'
import VueRouter from 'vue-router'
import { isMobile } from '@/utils'

Vue.use(VueRouter)

const routesPC = [
  {
    path: '/',
    name: '',
    component: () => import('@/views/pc/index.vue')
  },
  {
    path: '/psychologicalConsulting',
    name: '',
    component: () => import('@/views/pc/views/psychology.vue')
  },
  {
    path: '/homeEducation',
    name: '',
    component: () => import('@/views/pc/views/home.vue')
  },
  {
    path: '/authorized',
    name: '',
    component: () => import('@/views/pc/query.vue')
  },
  {
    path: '/excellentCourses',
    name: '',
    component: () => import('@/views/pc/views/excellentCourses.vue')
  }
]

const routerMobile = [
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/mobile/index.vue')
  },
  {
    path: '/authorized',
    name: '授权机构',
    component: () => import('@/views/mobile/authorized.vue')
  },
  {
    path: '/homeEducation',
    name: '家庭教育指导师培训',
    component: () => import('@/views/mobile/homeEducation.vue')
  },
  {
    path: '/psychologicalConsulting',
    name: '心理咨询专业技能培训',
    component: () => import('@/views/mobile/psychologicalConsulting.vue')
  },
  {
    path: '/excellentCourses',
    name: '线上精品课程',
    component: () => import('@/views/mobile/excellentCourses.vue')
  },
]

const routes = isMobile() ? routerMobile : routesPC
const router = new VueRouter({
  routes
})

export default router 